import React, { useEffect, useState, useRef, useMemo } from "react";
import { useSelector } from "react-redux";
import { debounce } from "lodash";
import ChartData from "./Modal/ChartData";
import Modal from "./Modal";

const SearchBar = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedData, setSelectedData] = useState(null);

  const searchBarRef = useRef(null); // Ref for search input and suggestions

  // Access allData from Redux store with a default empty array
  const allData = useSelector((state) => state.Settings.allData || []);

  // Debounced function to filter allData based on searchTerm
  const filterData = useMemo(
    () =>
      debounce((value) => {
        if (value.trim() === "") {
          setSuggestions([]);
          return;
        }

        // Example filtering by Symbol, adjust according to your data structure
        const filtered = allData.filter(
          (item) =>
            item.Symbol &&
            typeof item.Symbol === "string" &&
            item.Symbol.toLowerCase().includes(value.toLowerCase())
        );

        setSuggestions(filtered.slice(0, 10)); // Limit to top 10 suggestions
      }, 300), // Adjust debounce delay as needed
    [allData]
  );

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    filterData(value);
  };

  const openModal = (data) => {
    setIsModalOpen(true);
    setSelectedData(data);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedData(null);
  };

  // Close suggestions when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchBarRef.current &&
        !searchBarRef.current.contains(event.target)
      ) {
        setSuggestions([]); // Close the dropdown
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      filterData.cancel(); // Cancel any pending debounced calls on unmount
    };
  }, [filterData]);

  // Optional: Handle scenarios where allData is not yet loaded
  if (!allData) {
    return <div>Loading data...</div>;
  }

  return (
    <>
      <div className="relative" ref={searchBarRef}>
        <input
          type="search"
          id="default-search"
          className="block w-full p-2 pl-10 text-sm text-gray-400 rounded-lg focus:ring-purple-500 bg-[#363636]"
          placeholder="Search Security...."
          value={searchTerm}
          onChange={handleInputChange}
        />
        {suggestions.length > 0 ? (
          <ul className="absolute text-gray-400 bg-[#363636] border border-gray-300 w-full rounded-lg mt-1 z-10 max-h-[300px] overflow-y-auto">
            {suggestions.map((suggestion) => (
              <li
                key={suggestion.Symbol} // Assuming Symbol is unique
                className="p-2 hover:bg-gray-200 cursor-pointer"
                onClick={() => {
                  setSearchTerm(suggestion.Symbol); // Use the correct property
                  setSuggestions([]);
                  openModal(suggestion);
                }}
              >
                {suggestion.Symbol} - {suggestion.Name || "No Name Available"}
              </li>
            ))}
          </ul>
        ) : searchTerm.trim() !== "" ? (
          <ul className="absolute text-gray-400 bg-[#363636] border border-gray-300 w-full rounded-lg mt-1 z-10">
            <li className="p-2">No results found.</li>
          </ul>
        ) : null}
        {isModalOpen && (
          <Modal
            isOpen={isModalOpen}
            onClose={closeModal}
            data={selectedData}
          >
            <ChartData selectedData={selectedData} />
          </Modal>
        )}
      </div>
    </>
  );
};

export default SearchBar;
